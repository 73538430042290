<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Toast from "./Toast.svelte";
  import {
    objOfflineStatus,
    store,
    connection,
    currentReport,
    serverStatus,
    inetStatus,
  } from "../stores/store";
  import { location, push } from "svelte-spa-router";
  import { lastUpdate, version } from "../config";
  import { format, addDays } from "date-fns";
  import DateRangePicker from "./control/DateRangePicker.svelte";
  import util from "./Util/Utility";
	import { transactionReportType } from "../enums/transaction";

  const storedata = $store;

  let now = new Date();

  const defaultRange = {
    from: new Date(now.setHours(0, 0, 0, 0)),
    to: new Date(),
  };
  let priceSelect = 0;
</script>

<div class="relative">
  <slot />
  <Toast />
  {#if $location == "/" && storedata != undefined}
    <div class="absolute top-5 left-5 flex space-x-5">
      <div
        class=" flex items-center justify-center py-2 font-medium rounded-md px-6 bg-primary shadow-lg text-white text-md"
      >
        <i class="bx bx-pin mr-2" />
        {storedata.name}
      </div>
      <div class="w-60">
        <DateRangePicker
          bind:from={defaultRange.from}
          bind:to={defaultRange.to}
        />
      </div>
      <div class="relative">
        <select
          class="flex-1 appearance-none cursor-pointer block text-sm text-dark-second border dark:text-white dark:bg-dark-third border-black rounded p-2 ring-transparent focus:ring-transparent
        focus:border-gray-800 pr-7"
          bind:value={priceSelect}
        >
          {#each transactionReportType as option}
            {#if priceSelect == option["id"]}
              <option value={option["id"]} selected>{option.name}</option>
            {:else}
              <option value={option["id"]}>{option.name}</option>
            {/if}
          {/each}
        </select>
        <div class="absolute top-2 right-2">
          <i class="bx bx-chevron-down bx-xs text-primary" />
        </div>
      </div>
      <div
        class="flex items-center justify-center py-2 font-medium rounded-md px-6 bg-primary shadow-lg text-white text-md cursor-pointer"
        on:click={() => {
          if ($serverStatus && $inetStatus) {
            const reportType = transactionReportType.find((it) => it.id == priceSelect);
            dispatch("generate_report", {
              storeId: storedata.id || storedata._id,
              range: defaultRange,
              value: reportType.value,
            });
          } else {
            globalThis.pushToast(
              "You cannot open the Generate Report because you are currently offline.",
              "error"
            );
          }
        }}
      >
        Generate Report
      </div>
      <div
        class="flex items-center justify-center py-2 font-medium rounded-md px-6 bg-primary shadow-lg text-white text-md cursor-pointer"
        on:click={() => {
          if ($serverStatus && $inetStatus) {
            const reportType = transactionReportType.find((it) => it.id == priceSelect);
            dispatch("shift_report", {
              storeId: storedata.id || storedata._id,
              range: defaultRange,
              value: reportType.value,
            });
          } else {
            globalThis.pushToast(
              "You cannot open the Shift Report because you are currently offline.",
              "error"
            );
          }
        }}
      >
        Shift Report
      </div>
    </div>
  {/if}
  {#if $location != "/pos" && $location != "/sync-data" && $location != "/history"}
    <div class="absolute bottom-0 w-full text-center invisible xl:visible">
      <footer class="h-full border-t">
        <div class="container mx-auto px-5 py-3">
          <!-- <hr class="mb-4 border-b-1 border-gray-300" /> -->
          <div
            class="flex flex-col flex-wrap items-start justify-start w-full text-gray-500 text-xs font-light"
          >
            <p class="font-normal pb-1">Version: {version}</p>
            <p class="font-normal pb-1">Update: {lastUpdate}</p>
            <!-- <p class="font-normal pb-1">
              Network status: <strong class={$objOfflineStatus.statusColor}
                >{$objOfflineStatus.status}</strong
              >
            </p> -->
            <p class="font-bold">© Pesona Dari Timur</p>
          </div>
        </div>
      </footer>
    </div>
  {/if}
</div>
