<script lang="ts">
  import Cookies from "js-cookie";
  import { link, replace } from "svelte-spa-router";

  import CardHome from "../common/DataDisplay/CardHome.svelte";
  import {
    objOfflineStatus,
    connection,
    loggedInUserId,
    user,
    serverStatus,
    temporders,
  } from "../stores/store";
  import { db, version } from "../config";
  import TopNotif from "../common/TopNotif.svelte";

  // let users = JSON.parse(localStorage.getItem("user"));
</script>

<div
  class="container relative w-full max-w-full mx-auto flex justify-center items-center py-24 px-6 min-h-screen my-32 md:my-0"
>
  <div class="max-w-5xl w-full px-6 border border-gray-400 font-sans">
    <div class="relative flex flex-wrap">
      <div class="w-full relative">
        <div
          class="p-4 bg-white shadow-lg rounded-full flex items-center justify-center w-40 h-40 absolute -top-24"
          style="left: 50%; margin-left: -75px;"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="5334.000000pt"
            height="70pt"
            viewBox="0 0 5334.000000 3000.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,3000.000000) scale(0.100000,-0.100000)"
              fill="#9C6039"
              stroke="none"
            >
              <path
                d="M17845 29290 c11 -64 65 -160 120 -214 66 -65 152 -109 275 -141 l95
-25 -125 4 c-225 9 -390 67 -507 181 -35 34 -63 57 -63 53 0 -27 55 -110 101
-154 68 -63 123 -96 222 -129 142 -47 252 -58 557 -54 503 7 837 2 1019 -16
96 -9 178 -18 182 -20 3 -3 9 -28 13 -57 19 -165 21 -171 91 -278 106 -161
324 -375 574 -563 106 -79 232 -155 274 -164 15 -3 34 -15 42 -27 8 -11 40
-99 71 -196 62 -195 120 -325 184 -421 24 -34 103 -122 175 -193 201 -201 249
-274 284 -433 23 -104 28 -117 110 -243 65 -101 120 -248 142 -380 l12 -75
-50 -115 c-28 -63 -72 -177 -98 -252 -35 -102 -52 -138 -64 -137 -9 0 -49 6
-89 13 l-72 13 -118 115 c-115 112 -187 168 -289 223 -84 45 -100 69 -132 191
-47 175 -89 272 -146 328 -31 31 -34 38 -25 61 38 101 -26 232 -142 291 -48
24 -176 59 -185 50 -2 -2 7 -21 21 -43 34 -52 49 -118 42 -175 -10 -74 -13
-78 -61 -78 -40 0 -44 3 -73 49 -17 28 -56 69 -87 93 -32 24 -87 67 -124 95
-67 53 -106 65 -141 43 -13 -8 -12 -15 8 -47 12 -21 34 -62 49 -92 23 -47 24
-58 14 -91 -16 -53 -15 -54 29 -105 31 -36 39 -52 34 -69 -9 -28 -9 -29 -33
-21 -12 3 -32 25 -46 48 -36 63 -69 90 -180 147 -55 28 -109 60 -121 71 -11
10 -34 19 -52 19 -43 0 -42 -22 3 -79 39 -49 42 -68 19 -112 -9 -18 -12 -39
-8 -52 3 -12 55 -69 115 -127 60 -58 109 -110 109 -116 0 -5 -6 -18 -13 -28
-13 -18 -15 -17 -53 2 -51 26 -151 99 -239 176 -114 98 -104 92 -96 54 5 -18
20 -68 34 -110 15 -42 27 -95 27 -118 0 -81 44 -129 178 -191 255 -118 418
-289 502 -529 37 -105 107 -244 155 -308 52 -69 128 -131 206 -166 88 -41 436
-352 473 -424 14 -26 23 -31 103 -46 94 -18 188 -58 243 -104 l33 -29 -13
-139 c-18 -200 -25 -829 -11 -1059 22 -346 66 -695 127 -1006 l34 -176 -86
-164 c-47 -90 -93 -172 -102 -183 -9 -10 -48 -42 -87 -71 -111 -84 -289 -278
-330 -361 -14 -28 -31 -82 -37 -120 -5 -38 -18 -85 -28 -104 -72 -141 -203
-135 -690 30 l-236 80 -169 10 c-152 8 -171 8 -187 -7 -22 -20 -16 -26 37 -43
62 -20 126 -54 228 -123 128 -87 204 -132 257 -153 25 -9 49 -20 53 -24 11
-10 -82 -66 -132 -80 -63 -17 -109 0 -175 65 -35 35 -64 55 -79 55 -44 0 -275
-186 -255 -205 2 -2 21 0 43 5 75 18 134 12 158 -14 125 -133 156 -155 252
-180 l43 -11 -78 -140 c-43 -77 -77 -142 -75 -143 2 -2 34 15 72 37 38 23 80
41 94 41 14 0 65 -16 114 -36 110 -44 224 -74 282 -74 23 0 43 -3 43 -7 -1 -5
-19 -28 -40 -53 l-40 -45 51 -3 c28 -2 72 4 97 12 64 22 124 20 156 -5 14 -11
26 -24 26 -29 0 -5 -14 -25 -31 -45 -18 -20 -29 -38 -26 -41 10 -11 191 46
230 72 21 14 50 46 65 72 76 131 184 203 379 252 214 53 231 66 315 234 33 67
62 123 64 125 9 10 16 -13 35 -125 27 -165 62 -348 195 -1024 144 -737 165
-855 209 -1230 20 -168 42 -345 50 -395 8 -49 16 -135 19 -190 3 -55 8 -145
11 -200 7 -124 -10 -476 -31 -632 -46 -355 -133 -654 -264 -915 -95 -187 -196
-324 -343 -464 -230 -220 -499 -343 -857 -390 -174 -22 -472 -15 -623 15 -805
163 -1298 871 -1453 2086 -12 92 -18 222 -19 446 -2 277 -5 320 -20 352 -30
64 -91 57 -135 -16 -43 -70 -117 -333 -164 -587 -61 -324 -85 -667 -67 -948
14 -234 34 -369 82 -560 65 -260 147 -442 306 -685 322 -489 726 -813 1219
-976 171 -57 324 -92 509 -118 179 -24 665 -24 880 0 669 77 1112 218 1517
485 789 521 1275 1448 1452 2772 68 508 91 1148 61 1665 -3 44 -10 186 -15
315 -38 860 -86 1388 -154 1715 -8 39 -17 90 -21 115 l-6 45 44 -70 c25 -38
60 -95 80 -126 46 -74 101 -101 262 -129 66 -11 143 -28 170 -36 103 -33 190
-98 245 -184 23 -36 46 -56 84 -75 52 -25 213 -62 224 -51 3 4 -9 22 -28 40
-36 38 -35 56 2 80 34 22 76 25 143 9 38 -9 79 -12 110 -7 l50 7 -48 42 c-51
47 -56 55 -30 55 45 0 160 38 277 91 71 33 137 59 146 59 8 0 48 -16 89 -35
40 -19 74 -35 77 -35 2 0 -37 62 -86 137 l-89 138 37 11 c82 24 178 97 223
171 33 53 68 66 157 58 l77 -7 -35 37 c-45 47 -207 148 -238 148 -17 0 -43
-19 -82 -61 -64 -66 -110 -88 -168 -77 -41 8 -142 54 -142 65 0 4 29 22 64 39
35 18 121 78 192 133 134 104 215 156 278 179 42 15 47 36 11 45 -27 7 -276
-20 -340 -37 -22 -6 -132 -51 -245 -99 -245 -105 -411 -161 -501 -167 -118 -9
-183 46 -214 182 -25 107 -57 155 -185 278 -105 102 -183 162 -269 209 -19 10
-53 52 -88 107 -32 50 -84 131 -115 180 l-58 90 15 125 c43 373 27 733 -51
1125 l-27 135 75 70 c41 39 108 91 148 116 105 65 149 108 202 198 95 159 173
233 345 328 203 112 311 286 333 534 18 211 96 422 203 551 70 84 138 142 273
232 129 85 158 127 151 212 -3 29 3 82 14 128 35 144 33 144 -97 8 -167 -174
-231 -216 -251 -166 -7 19 40 88 107 160 86 91 99 129 60 178 -25 31 -25 48
-1 78 35 46 51 85 38 98 -20 20 -46 12 -90 -27 -23 -22 -71 -58 -107 -82 -91
-60 -118 -87 -151 -152 -25 -49 -66 -88 -94 -88 -14 0 -2 51 20 81 13 19 32
48 41 66 16 29 16 34 0 74 -20 50 -15 81 26 162 23 46 27 62 17 73 -26 31 -58
15 -165 -85 -113 -105 -158 -162 -174 -220 -5 -20 -20 -49 -33 -65 l-24 -30
-34 69 c-29 59 -33 79 -33 139 0 53 6 82 22 113 12 23 22 45 22 48 0 9 -52 -6
-116 -35 -165 -74 -228 -211 -164 -358 l23 -53 -25 -27 c-66 -72 -83 -115
-103 -260 -20 -144 -45 -187 -155 -268 -107 -78 -184 -158 -293 -306 -88 -118
-134 -165 -148 -151 -8 9 -37 223 -74 558 -40 358 -74 524 -127 615 -11 19
-27 63 -34 97 -7 35 -41 129 -75 210 -33 82 -86 209 -115 283 -57 140 -137
293 -232 440 -107 165 -130 208 -193 351 -190 435 -378 735 -554 882 -86 72
-401 283 -550 368 -229 131 -454 224 -611 255 -101 19 -143 18 -260 -7 -84
-18 -122 -20 -244 -16 -126 4 -161 10 -270 40 -69 20 -145 43 -170 52 -24 9
-88 21 -141 27 -234 28 -423 -48 -493 -197 -15 -30 -26 -61 -26 -69 0 -19 11
-20 -104 4 -269 55 -529 79 -966 90 -292 7 -345 10 -415 29 -177 46 -328 149
-394 268 -29 53 -31 55 -26 23z m3805 -580 c105 -29 190 -128 190 -222 0 -27
-3 -48 -6 -48 -3 0 -20 14 -37 32 -40 39 -113 73 -197 91 -36 7 -76 19 -90 27
-69 35 -90 85 -48 114 26 18 132 22 188 6z"
              />
              <path
                d="M29795 25854 c-149 -17 -223 -30 -302 -50 -374 -96 -621 -336 -716
-694 -44 -162 -52 -254 -62 -650 -3 -118 -8 -282 -11 -365 -2 -82 -22 -769
-44 -1525 -22 -756 -42 -1451 -45 -1545 -11 -311 -55 -1856 -55 -1915 l0 -57
281 -8 c155 -4 291 -8 303 -10 12 -2 112 -6 224 -10 l202 -7 0 43 c0 53 97
2217 106 2358 l6 104 137 -7 c313 -15 1066 -58 1069 -61 2 -2 -13 -257 -33
-566 -19 -310 -53 -847 -75 -1194 -22 -346 -42 -655 -44 -686 l-5 -55 272 -18
c150 -10 376 -26 502 -36 127 -10 231 -17 233 -15 3 3 23 256 327 4080 151
1913 138 1720 126 1858 -13 136 -47 276 -89 370 -143 314 -443 511 -893 586
-245 41 -1246 94 -1414 75z m755 -834 c159 -10 224 -26 314 -73 114 -61 205
-180 221 -292 4 -23 -10 -302 -30 -621 -20 -318 -53 -840 -74 -1159 l-36 -580
-50 3 c-28 1 -167 9 -310 17 -143 8 -395 22 -559 30 -165 9 -302 18 -304 20
-8 8 98 2300 109 2344 33 141 160 262 322 306 62 16 197 18 397 5z"
              />
              <path
                d="M35655 25303 c10 -123 78 -1624 110 -2438 32 -828 67 -1901 80 -2460
3 -132 7 -287 10 -345 3 -58 10 -368 16 -690 6 -322 14 -668 17 -770 l7 -185
50 -7 c169 -25 965 -148 991 -154 l31 -6 109 334 c267 822 1068 3200 1155
3433 19 49 19 41 14 -760 -3 -445 -7 -868 -10 -940 -3 -71 -7 -314 -10 -540
-6 -428 -20 -1105 -31 -1501 -6 -221 -5 -232 12 -237 11 -3 145 -29 299 -58
154 -28 387 -73 518 -99 l239 -47 10 26 c5 14 48 127 95 251 740 1959 1605
4142 2355 5944 54 132 95 242 91 246 -8 7 -138 36 -693 151 -184 38 -345 71
-356 74 -25 6 11 97 -331 -840 -463 -1272 -976 -2719 -1340 -3780 -47 -137
-90 -254 -95 -260 -4 -5 -6 17 -3 50 11 119 79 1827 110 2720 8 242 17 510 20
595 10 274 34 1146 40 1450 3 162 8 312 10 333 3 20 1 37 -2 37 -4 0 -122 20
-263 45 -482 85 -590 102 -594 96 -4 -6 -291 -889 -479 -1471 -259 -801 -660
-2074 -956 -3039 -72 -233 -133 -427 -137 -430 -3 -3 -2 188 2 424 16 897 8
4745 -11 4763 -5 5 -283 45 -675 97 -190 25 -360 48 -378 51 l-33 5 6 -68z"
              />
              <path
                d="M14335 24838 c-130 -7 -243 -25 -710 -113 -561 -105 -637 -124 -830
-202 -355 -144 -580 -379 -655 -683 -27 -108 -36 -304 -21 -433 6 -54 68 -362
137 -685 69 -323 215 -1005 324 -1517 109 -511 323 -1515 476 -2231 l277
-1301 111 24 c61 13 275 58 476 99 201 40 375 76 386 79 l21 5 -33 167 c-156
784 -444 2260 -444 2274 0 20 -13 17 385 93 487 93 797 148 802 142 5 -5 61
-314 303 -1676 71 -404 132 -747 135 -761 l6 -26 97 18 c53 10 277 48 497 85
220 37 401 67 402 68 1 1 -14 96 -32 211 -19 116 -152 937 -295 1825 -143 888
-285 1766 -315 1950 -30 184 -90 560 -135 835 -147 910 -148 920 -190 1047
-170 516 -549 744 -1175 706z m-180 -883 c90 -19 161 -55 224 -111 98 -88 86
-37 311 -1308 140 -790 199 -1141 191 -1145 -6 -4 -118 -27 -249 -50 -130 -24
-397 -74 -592 -111 -195 -37 -356 -67 -357 -66 -2 2 -64 313 -268 1351 -69
352 -140 710 -157 795 -27 138 -30 162 -20 218 15 90 55 162 128 232 108 104
180 128 604 203 52 9 117 6 185 -8z"
              />
              <path
                d="M45745 23209 c-406 -39 -680 -258 -845 -674 -17 -42 -91 -300 -164
-573 -74 -273 -218 -807 -321 -1187 -194 -718 -839 -3105 -975 -3610 -48 -179
-74 -292 -68 -296 5 -4 62 -20 126 -37 65 -17 279 -77 476 -132 198 -55 363
-97 367 -93 6 7 140 467 601 2076 58 204 96 317 104 317 14 0 598 -171 947
-277 128 -39 216 -71 214 -77 -5 -13 -222 -719 -502 -1631 -108 -352 -204
-666 -215 -697 l-18 -57 131 -41 c73 -23 292 -92 487 -154 195 -62 356 -113
357 -112 1 1 127 387 848 2606 707 2177 924 2847 939 2900 43 155 59 391 35
526 -64 365 -336 643 -808 828 -52 21 -234 79 -405 131 -694 209 -839 245
-1056 265 -126 11 -139 11 -255 -1z m885 -983 c233 -70 294 -100 380 -185 88
-87 122 -157 128 -267 l4 -81 -342 -1114 c-188 -613 -343 -1115 -344 -1117 -2
-1 -108 30 -237 69 -129 39 -393 118 -588 175 -195 57 -356 106 -359 108 -2 3
28 116 68 253 39 136 168 586 286 998 285 992 282 984 310 1032 45 78 157 157
261 183 21 5 85 8 143 5 93 -3 127 -10 290 -59z"
              />
              <path
                d="M7040 23134 c-173 -27 -315 -64 -702 -180 -523 -157 -1524 -470
-1536 -480 -3 -2 2083 -6434 2108 -6499 9 -22 10 -22 287 67 389 125 895 280
1296 399 481 141 577 173 705 234 315 151 491 342 563 610 28 105 31 309 5
439 -20 102 -466 1762 -496 1848 -33 92 -70 149 -144 224 -158 157 -370 226
-746 242 l-165 7 -3 25 c-2 22 6 29 61 58 191 98 371 257 439 387 64 123 85
238 69 365 -10 72 -344 1320 -398 1485 -64 198 -162 365 -287 491 -135 136
-275 214 -479 265 -110 28 -435 36 -577 13z m78 -909 c77 -35 158 -118 201
-208 27 -56 318 -1039 357 -1205 63 -266 -89 -476 -424 -585 -304 -99 -524
-161 -531 -149 -15 24 -603 1953 -597 1959 6 7 521 164 651 199 127 34 253 30
343 -11z m807 -2745 c145 -21 264 -107 324 -233 16 -34 102 -316 191 -627 89
-311 188 -657 221 -769 38 -131 59 -223 59 -255 0 -108 -74 -202 -204 -261
-39 -18 -239 -83 -445 -144 -205 -62 -384 -117 -398 -122 -20 -7 -25 -5 -33
14 -6 12 -89 281 -186 597 -97 316 -245 800 -330 1075 -84 275 -150 503 -146
507 14 13 638 196 707 207 146 23 150 23 240 11z"
              />
              <path
                d="M25485 14486 c-43 -19 -50 -29 -158 -240 -311 -607 -710 -1423 -989
-2021 -82 -176 -150 -321 -151 -322 -2 -1 -65 11 -142 26 -993 201 -2520 353
-4110 411 -817 29 -1026 33 -1856 33 -722 0 -947 -4 -1599 -24 -1598 -48
-3124 -174 -4430 -364 -2708 -395 -4343 -1042 -4726 -1870 -70 -151 -103 -349
-70 -413 34 -66 111 -100 186 -83 87 19 116 59 130 179 43 376 349 696 945
991 1318 653 3992 1088 7520 1226 652 25 1005 30 2165 30 1105 -1 1412 -6
2175 -41 1397 -62 2621 -181 3455 -335 91 -17 180 -33 198 -36 19 -3 31 -11
28 -17 -2 -6 -59 -132 -126 -281 -852 -1889 -1710 -4104 -2007 -5184 -66 -243
-83 -337 -89 -511 -14 -357 81 -628 293 -843 133 -134 298 -221 448 -237 87
-9 136 -7 270 12 361 50 902 318 1433 710 52 38 96 67 98 65 3 -2 -2 -46 -11
-98 -72 -440 22 -809 269 -1055 156 -155 382 -266 651 -321 69 -14 135 -18
285 -17 173 0 209 3 322 27 409 87 811 320 1168 677 498 497 834 1148 910
1763 18 138 8 425 -18 537 -72 309 -222 532 -468 696 -115 76 -222 124 -279
124 -117 0 -196 -104 -166 -218 17 -63 55 -98 155 -147 231 -114 384 -298 446
-538 144 -563 -170 -1384 -755 -1973 -552 -555 -1184 -744 -1741 -519 -344
138 -517 452 -475 862 15 146 43 277 88 412 39 119 56 154 96 205 19 23 31 52
36 89 17 126 269 528 526 837 108 130 377 398 508 506 472 388 969 610 1412
631 146 6 190 23 224 84 19 33 22 52 19 98 -10 143 -100 174 -390 136 -932
-123 -1958 -956 -2535 -2060 -64 -122 -73 -134 -158 -209 -133 -117 -380 -313
-546 -431 -543 -390 -1029 -596 -1310 -557 -236 34 -379 166 -451 418 -32 114
-32 350 1 514 110 552 747 2310 1448 3995 226 543 733 1709 752 1728 20 20
447 -117 617 -198 129 -61 181 -95 271 -176 36 -33 80 -63 101 -68 114 -32
237 88 203 198 -24 82 -199 212 -408 305 -151 67 -424 159 -610 206 -24 6 -43
15 -43 21 0 37 585 1269 950 1999 234 468 242 487 237 533 -2 27 -13 61 -24
75 -41 56 -130 78 -198 48z"
              />
              <path
                d="M16845 10021 c-41 -26 -86 -75 -267 -291 -843 -1010 -1601 -2060
-2142 -2970 -71 -118 -131 -218 -135 -222 -4 -5 -58 -17 -121 -28 -866 -154
-1551 -365 -1952 -603 -113 -67 -143 -106 -143 -184 0 -56 18 -90 69 -127 21
-16 42 -21 94 -21 63 0 72 3 155 53 271 165 672 310 1152 417 196 44 546 107
553 101 2 -2 -54 -118 -124 -257 -70 -140 -156 -319 -191 -399 -366 -824 -514
-1520 -428 -2015 32 -183 56 -233 129 -266 84 -38 172 -15 213 57 36 63 38
109 9 224 -113 446 4 1044 361 1855 77 174 319 661 396 795 50 88 6 74 402
125 560 71 1182 121 1800 144 284 11 1273 11 1525 0 600 -25 1154 -84 1510
-159 302 -63 482 -129 624 -227 44 -30 57 -34 94 -30 121 13 188 136 133 244
-25 51 -61 78 -166 131 -332 167 -1000 285 -1925 342 -558 34 -1621 34 -2255
0 -446 -24 -1008 -71 -1329 -110 -88 -11 -162 -18 -164 -15 -3 2 46 87 109
187 606 979 1329 1969 2055 2814 100 117 187 221 193 232 33 61 1 153 -68 199
-44 29 -123 31 -166 4z"
              />
              <path
                d="M41017 9496 c-145 -61 -257 -198 -332 -409 -74 -206 -84 -619 -25
-1012 11 -71 22 -142 25 -156 5 -21 -15 -55 -111 -190 -445 -626 -1012 -1261
-1501 -1682 -102 -88 -135 -128 -147 -178 -7 -30 -172 -177 -426 -378 -758
-602 -1472 -953 -2110 -1037 -238 -31 -518 -9 -718 57 -425 139 -723 496 -836
1001 -39 175 -48 157 105 233 550 276 1173 676 1609 1034 611 501 958 970
1010 1364 38 285 -112 570 -364 692 -112 54 -211 75 -354 75 -294 0 -631 -120
-967 -345 -185 -123 -281 -202 -449 -365 -444 -433 -746 -952 -887 -1525 -48
-197 -70 -369 -76 -602 l-6 -212 -154 -128 c-723 -599 -1318 -908 -1654 -856
-94 15 -201 68 -263 130 -58 59 -111 163 -133 263 -24 108 -21 378 6 580 62
456 75 591 75 820 0 199 -3 234 -21 301 -81 291 -292 486 -805 744 -91 46
-204 105 -250 131 -134 76 -223 127 -226 129 -2 2 11 94 28 206 53 343 51 540
-7 739 -109 378 -436 527 -705 322 -240 -183 -312 -659 -148 -982 67 -132 229
-310 362 -400 37 -25 68 -50 68 -56 0 -6 -25 -103 -56 -214 -363 -1329 -987
-2676 -1630 -3521 -210 -276 -464 -542 -659 -689 -177 -134 -212 -185 -186
-272 26 -85 105 -128 197 -108 116 26 452 327 715 640 641 764 1288 2016 1723
3335 61 185 196 635 196 653 0 2 42 -19 93 -46 50 -28 206 -109 346 -181 289
-150 389 -212 482 -299 79 -74 123 -152 140 -246 16 -94 6 -403 -20 -616 -11
-91 -30 -246 -42 -345 -28 -231 -30 -524 -5 -669 33 -197 99 -343 205 -458
359 -388 942 -308 1766 242 148 99 363 259 510 378 84 68 101 79 107 64 4 -9
18 -60 33 -112 36 -134 69 -218 137 -352 80 -158 163 -273 288 -398 225 -226
492 -360 845 -427 111 -21 447 -29 588 -13 810 87 1720 557 2641 1363 214 188
285 259 292 295 5 23 32 55 103 118 409 371 892 919 1259 1427 47 65 88 114
92 110 9 -10 201 -609 234 -728 152 -556 181 -1069 81 -1443 -62 -233 -157
-401 -315 -563 -184 -188 -392 -299 -690 -368 -167 -39 -314 -53 -570 -55
-225 -1 -248 -3 -279 -21 -47 -28 -71 -77 -71 -146 0 -68 26 -113 83 -142 34
-17 60 -19 255 -20 322 0 576 35 816 114 992 325 1368 1304 1035 2689 -54 225
-110 406 -179 586 -53 134 -150 442 -150 473 0 6 35 64 78 129 153 228 304
504 365 667 43 114 68 240 74 380 8 203 -28 319 -127 408 -70 63 -142 89 -260
94 -83 3 -102 1 -153 -21z m205 -328 c23 -11 32 -49 25 -103 -12 -86 -135
-426 -223 -614 -43 -93 -49 -101 -58 -80 -17 43 -12 404 7 501 37 186 76 275
132 303 30 15 82 12 117 -7z m-10546 -162 c89 -94 118 -365 70 -646 -8 -47
-18 -109 -21 -137 -4 -29 -11 -53 -16 -53 -17 0 -143 134 -183 194 -68 102
-81 152 -81 311 0 162 22 253 76 318 55 66 101 70 155 13z m6245 -416 c134
-25 264 -130 309 -247 62 -162 1 -384 -173 -625 -352 -489 -1212 -1135 -2116
-1588 l-141 -70 0 59 c0 240 85 629 197 901 255 618 744 1167 1280 1434 244
122 467 169 644 136z"
              />
              <path
                d="M25220 1570 l0 -550 100 0 100 0 0 368 c0 203 3 371 6 375 3 3 148
-163 321 -369 l315 -374 79 0 79 0 0 550 0 550 -105 0 -105 0 -2 -372 -3 -371
-313 371 -313 372 -80 0 -79 0 0 -550z"
              />
              <path
                d="M26359 2118 c-1 -2 -3 -40 -4 -85 l-1 -83 183 0 183 0 0 -475 0 -475
105 0 105 0 0 475 0 475 185 0 185 0 0 85 0 85 -470 0 c-258 0 -471 -1 -471
-2z"
              />
              <path
                d="M27330 2035 l0 -85 185 0 185 0 0 -475 0 -475 105 0 105 0 0 475 0
475 185 0 185 0 0 85 0 85 -475 0 -475 0 0 -85z"
              />
              <path
                d="M20713 1504 c-3 -8 -1 -20 4 -25 5 -5 718 -8 1854 -7 1836 3 1844 3
1844 23 0 20 -8 20 -1848 23 -1671 2 -1848 1 -1854 -14z"
              />
              <path
                d="M28927 1513 c-4 -3 -7 -15 -7 -25 0 -17 61 -18 1853 -16 1844 3 1852
3 1852 23 0 20 -8 20 -1846 23 -1015 1 -1849 -1 -1852 -5z"
              />
            </g>
          </svg>
        </div>
        <h4 class="text-center text-xl font-semibold text-dark-second pt-20">
          Welcome Back, <strong>{$user.profile?.name}</strong>
        </h4>

        <div class="flex flex-col md:flex-row mt-7 flex-wrap">
          <CardHome
            icon="bx bx-user"
            title="account"
            on:click={() => {
              if ($objOfflineStatus.status == "online" && $serverStatus) {
                replace("/account");
              } else {
                globalThis.pushToast(
                  "You cannot open the Account because you are currently offline.",
                  "error"
                );
              }
            }}
          />
          <div class="mx-3" />
          <CardHome
            icon="bx bx-cart"
            title="POS"
            on:click={() => replace("/pos")}
          />
          <div class="mx-3" />
          <CardHome
            icon="bx bx-star"
            title="promo"
            on:click={() => replace("/promo")}
          />
          <div class="mx-3" />
          <CardHome
            icon="bx bx-history"
            title="history"
            online
            on:click={() => {
              if ($objOfflineStatus.status == "online" && $serverStatus) {
                replace("/history");
              } else {
                globalThis.pushToast(
                  "You cannot open the History because you are currently offline.",
                  "error"
                );
              }
            }}
          />
          <div class="mx-3" />
          <CardHome
            icon="bx bx-data"
            title="sync data"
            count
            on:click={() => replace("/sync-data")}
          />
          <div class="mx-3" />
          <CardHome
            icon="bx bx-store"
            title="menu"
            on:click={() => replace("/menu")}
          />
        </div>

        <div class="flex mt-7 flex-wrap" />
      </div>
    </div>
  </div>
  <div class="absolute top-0 right-0 w-full text-right pr-4 pt-4">
    <button
      on:click={() => {
        if ($temporders.length > 0) {
          globalThis.pushToast(
            "You cant Logout! You still have local transaction.",
            "error"
          );
        } else if ($objOfflineStatus.status == "online" && $serverStatus) {
          replace("/login");
          localStorage.setItem("idStore", null);
          $connection.logout();
          window.location.reload();
        } else {
          globalThis.pushToast("Cannot logout in offline mode", "error");
        }
      }}
      class="text-md font-medium">Logout</button
    >
  </div>

  <!-- <TopNotif/> -->
</div>
