<script>
	import { createEventDispatcher } from "svelte";
	import { paymentTypes } from "../../stores/store";
	import Input from "../control/Input.svelte";
	import MoneyInput from "../control/MoneyInput.svelte";
	import Modal from "../Modal.svelte";
	import Util from "../Util/Utility";
	import { form as Form } from "svelte-forms";
	import { excludedPaymentType, paymentTypeEnum } from "../../enums/paymentType";

	const dispatch = createEventDispatcher();
	export let codeScan;
	export let customer;
	export let fromScanCode;
	export let paymentDetail;
	export let paymentType;
	export let paymentValue;
	export let total;

	let modalPay;
	let loadingPay = false;
	const formPay = Form(
		() => ({
			customer: { value: customer, validators: ["required"] },
			paymentValue: {
				value: paymentValue,
			},
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);
	const formKredit = Form(
		() => ({
			paymentDetail: { value: paymentDetail, validation: ["required"] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);
	export function open() {
		modalPay.toggle();
	}

	export function close() {
		modalPay.close();
	}
</script>

<Modal
	title="Payment"
	withTitle={false}
	bind:this={modalPay}
	on:close={() => {
		dispatch("closeModalPayment");
	}}
	index={20}
	closed
>
	<div>
		<p class="text-lg text-center m-0">Total</p>
		<h4 class="text-2xl font-medium mb-5 text-center">
			{Util.formatRupiah(total, "Rp. ")}
		</h4>
		<div class="relative flex flex-col space-y-4 mb-5">
			<div class="flex space-x-2">
				<Input form={formPay} name="customer name" label="Customer Name" bind:value={customer.name} />
				<Input type="tel" form={formPay} name="customer" label="Customer Phone" min="0" bind:value={customer.phone} />
				<Input form={formPay} name="table" label="Table" bind:value={customer.table} disabled={!!fromScanCode} />
			</div>
			<div class="flex space-x-2">
				<div class="flex flex-1">
					<MoneyInput form={formPay} autofocus name="paymentValue" label="Payment Amount" bind:value={paymentValue} />
				</div>
				<div class="flex flex-1">
					<div
						on:click={() => {
							paymentValue = total;
						}}
						class="bg-primary mt-6 w-full text-white py-2 px-10 flex justify-center items-center cursor-pointer rounded-lg"
					>
						<p class="font-medium text-md">Pay As Total</p>
					</div>
				</div>
			</div>
			<!-- <div class="flex space-x-2">
				<div
					class="font-semibold border-2 border-primary rounded-full px-5 py-1 h-8 flex justify-center items-center cursor-pointer"
					on:click="{() => {
						paymentValue = paymentValueMap + 25000;
					}}"
				>
					Rp. 20.000
				</div>
				<div
					class="font-semibold border-2 border-primary rounded-full px-5 py-1 h-8 flex justify-center items-center cursor-pointer"
					on:click="{() => {
						paymentValue = paymentValueMap + 50000;
					}}"
				>
					Rp. 50.000
				</div>
				<div
					class="font-semibold border-2 border-primary rounded-full px-5 py-1 h-8 flex justify-center items-center cursor-pointer"
					on:click="{() => {
						paymentValue = paymentValueMap + 100000;
					}}"
				>
					Rp. 100.000
				</div>
			</div> -->
			<div class="flex flex-col">
				<div class="font-bold text-dark-second mb-2 text-xs uppercase">Payment Type</div>
				<div class="grid grid-cols-3 gap-3">
					{#each $paymentTypes.filter((e) => !excludedPaymentType.includes(e.code)) as payment}
						<div
							class="font-semibold border-2 border-primary rounded-full px-5 py-1 h-8 flex justify-center items-center {paymentType ==
							payment['code']
								? 'bg-primary text-white'
								: ''}  cursor-pointer"
							on:click={() => {
								paymentType = payment["code"];
							}}
						>
							{payment["name"]}
						</div>
					{/each}
				</div>
				{#if paymentType.includes(paymentTypeEnum.Credit.code) || paymentType.includes(paymentTypeEnum.Debit.code)}
					<div class="flex flex-col mt-5 space-y-2">
						<Input form={formKredit} name="credit name" label="Name on card" bind:value={paymentDetail.name} />
						<Input
							form={formKredit}
							name="card number"
							type="creditcard"
							label="Card Number (6 Last Number)"
							placeholder="XXX XXX"
							bind:value={paymentDetail.numberCard}
						/>
						<div class="flex space-x-2">
							<Input
								form={formKredit}
								name="exp date"
								type="expdate"
								label="Expired Date"
								placeholder="MM/YY"
								bind:value={paymentDetail.expDate}
							/>
							<!-- <Input
								form="{formKredit}"
								name="cvv"
								type="cvv"
								label="CVV"
								placeholder="000"
								maxlength="{3}"
								bind:value="{paymentDetail.cvv}"
							/> -->
						</div>
					</div>
				{/if}
			</div>
		</div>
		<div class="flex flex-row justify-between items-center mt-6">
			{#if !loadingPay}
				<div
					on:click={() => {
						modalPay.close();
						if (codeScan == "") {
							customer.name = "";
							customer.phone = null;
						}
						paymentType = "CASH";
						paymentValue = 0;
						paymentDetail = {
							name: "",
							numberCard: null,
							cvv: null,
							expDate: null,
						};
					}}
					class="bg-gray-400 text-white flex-1 py-2 flex justify-center items-center cursor-pointer rounded-tl-lg rounded-bl-lg"
				>
					<h4 class="font-bold text-lg">Cancel</h4>
				</div>
			{/if}
			<div
				on:click={() => {
					if (Util.getPaymentValueMap(paymentValue) < total) {
						formPay.validate();
						globalThis.pushToast("Sorry! Payment amount is less than Total.", "error");
					} else {
						const valid = $formPay.valid;
						dispatch("processTransaction", valid);
					}
				}}
				class="bg-primary text-white flex-1 py-2 flex justify-center items-center cursor-pointer rounded-tr-lg rounded-br-lg"
			>
				{#if loadingPay}
					<i class="bx bx-loader-circle bx-spin text-2xl text-white" />
				{:else}
					<h4 class="font-medium text-lg">Pay</h4>
				{/if}
			</div>
		</div>
	</div>
</Modal>
