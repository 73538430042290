<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Util from "../Util/Utility";
  import { imageUrlValid } from "../../Utils/helper";
  export let item;
  export let cart;
  export let ojolPrice;

  let splitUrl = item.images[0].url.split("/");
  let imageUrl =
    splitUrl[0] +
    splitUrl[1] +
    "//" +
    splitUrl[2] +
    "/" +
    splitUrl[3] +
    "/tr:w-200/" +
    splitUrl[4];
</script>

<div
  on:click={() => {
    dispatch("onclick");
  }}
  class="my-2 pb-3 flex flex-col relative items-center flex-wrap bg-white rounded-lg shadow-md cursor-pointer hover:opacity-90"
>
  <!-- image: w-200 -->
  <img
    src={imageUrlValid(item["images"][0].url)}
    alt=".."
    class="object-cover rounded-tl-md rounded-tr-md"
    style="width: 100%; height: 240px;"
  />
  <h4
    class="text-sm mx-2 2xl:text-xl font-medium mt-4 text-center text-primary"
  >
    {item["name"]}
  </h4>
  <div class="flex items-center mt-1">
    <p class="text-sm 2xl:text-lg font-medium text-gray-800">
      {Util.formatRupiah(
        ojolPrice ? item["priceOnline"] ?? 0 : item["price"],
        "Rp. "
      )}
    </p>
  </div>
  {#if item["availability"] == "NOT_AVAILABLE"}
    <div
      class="absolute flex items-center justify-center px-4 py-1 top-3 right-2 rounded-full bg-red-500 text-white text-sm"
    >
      Out of Stock
    </div>
  {/if}
  {#if cart.filter((itemCart) => itemCart.id == item["id"]).length > 0}
    <div
      class="absolute top-0 right-0 flex items-center justify-center w-7 h-7 rounded-full bg-green-500 text-white text-sm"
    >
      {cart.filter((itemCart) => itemCart.id == item["id"]).length}
    </div>
  {/if}
</div>
