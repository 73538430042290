<script lang="ts">
  import Layout from "../common/Layout.svelte";
  import { connection, objOfflineStatus, user } from "../stores/store";
  import AccountTemplate from "../templates/AccountTemplate.svelte";

  let _name;
  let _gender;
  let _image = "https://bit.ly/3pPY1jc";

  user.subscribe((u) => {
    if (u) {
      _name = u.profile?.name;
      _gender = u.profile?.gender;
      _image = u.profile?.image ? u.profile?.image : "https://bit.ly/3pPY1jc";
    }
  });

  const updateUser = (e) => {
    const { fullname, gender, image } = e.detail;

    _name = fullname;
    _gender = gender;
    _image = image;

    if ($objOfflineStatus.status == "online") {
      $connection.call("users.update.profile", image, fullname, gender);
    }

    globalThis.pushToast("Successfully Update Account.", "success");
  };
</script>

<Layout>
  <AccountTemplate
    bind:fullname={_name}
    gender={_gender}
    image={_image}
    on:updateUser={updateUser}
  />
</Layout>
