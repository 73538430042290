<script>
	import { onMount } from "svelte";
	import { paymentTypes } from "../stores/store";
	import Input from "./control/Input.svelte";
	import { form as Form } from "svelte-forms";
	import { excludedPaymentType, paymentTypeEnum } from "../enums/paymentType";

	export let moreExcludedPaymentType = [];
	export let paymentType = "";
	export let paymentDetail = {
		name: "",
		numberCard: null,
		cvv: null,
		expDate: null,
	};
	export let title = "Payment Type";
	export let titleColor = "text-dark-second";
	let combinedExcludedPaymentType = [];
	const formKredit = Form(
		() => ({
			paymentDetail: { value: paymentDetail, validation: ["required"] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);
	onMount(() => {
		if (moreExcludedPaymentType && Array.isArray(moreExcludedPaymentType)) {
			combinedExcludedPaymentType = [...excludedPaymentType, ...moreExcludedPaymentType];
		}
	});
</script>

<div class="flex flex-col">
	<div class="font-bold {titleColor} mb-2 text-xs uppercase">{title}</div>
	<div class="grid grid-cols-3 gap-3">
		{#each $paymentTypes.filter((e) => !combinedExcludedPaymentType.includes(e.code)) as payment}
			<div
				class="font-semibold border-2 border-primary rounded-full px-5 py-1 h-8 flex justify-center items-center {paymentType ==
				payment['code']
					? 'bg-primary text-white'
					: ''}  cursor-pointer"
				on:click={() => {
					paymentType = payment["code"];
				}}
			>
				{payment["name"]}
			</div>
		{/each}
	</div>
	{#if paymentType.includes(paymentTypeEnum.Credit.code) || paymentType.includes(paymentTypeEnum.Debit.code)}
		<div class="flex flex-col mt-5 space-y-2">
			<Input form={formKredit} name="credit name" label="Name on card" bind:value={paymentDetail.name} />
			<Input
				form={formKredit}
				name="card number"
				type="creditcard"
				label="Card Number (6 Last Number)"
				placeholder="XXX XXX"
				bind:value={paymentDetail.numberCard}
			/>
			<div class="flex space-x-2">
				<Input
					form={formKredit}
					name="exp date"
					type="expdate"
					label="Expired Date"
					placeholder="MM/YY"
					bind:value={paymentDetail.expDate}
				/>
				<!-- <Input
						  form="{formKredit}"
						  name="cvv"
						  type="cvv"
						  label="CVV"
						  placeholder="000"
						  maxlength="{3}"
						  bind:value="{paymentDetail.cvv}"
					  /> -->
			</div>
		</div>
	{/if}
</div>
